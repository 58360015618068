<template>
  <div>
   <Header :title="title"/>
    

    <div class="content_podat">
      <div v-for="o in arr" :key="o.obdvigatel" class="block_radio">
        <input
          class="custom-radio"
          name="color"
          v-model="obdvigatel"
          type="radio"
          :id="o.obdvigatel"
          :value="o.obdvigatel"
          @change="select"
        />
        <label :for="o.obdvigatel">{{o.obdvigatel}} л.</label>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/headerpodat'
export default {
   components:{
   Header
   },
  data() {
    return {
      title:"Выберите объем двигателя",
      obdvigatel: "",
       arr: [
        {obdvigatel: 0.8},{obdvigatel: 0.9},
        {obdvigatel: 1},{obdvigatel: 1.1},{obdvigatel: 1.2},{obdvigatel: 1.3},{obdvigatel: 1.4},{obdvigatel: 1.5},
        {obdvigatel: 1.6},{obdvigatel: 1.7},{obdvigatel: 1.8},{obdvigatel: 1.9},{obdvigatel: 2},{obdvigatel: 2.1},
        {obdvigatel: 2.2},{obdvigatel: 2.3},{obdvigatel: 2.4},{obdvigatel: 2.5},{obdvigatel: 2.6},{obdvigatel: 2.7},
        {obdvigatel: 2.8},{obdvigatel: 2.9},{obdvigatel: 3},{obdvigatel: 3.1},{obdvigatel: 3.2},{obdvigatel: 3.3},
        {obdvigatel: 3.4},{obdvigatel: 3.5},{obdvigatel: 3.6},{obdvigatel: 3.7},{obdvigatel: 3.8},{obdvigatel: 3.9},
        {obdvigatel: 4},{obdvigatel: 4.1},{obdvigatel: 4.2},{obdvigatel: 4.3},{obdvigatel: 4.4},{obdvigatel: 4.5},
        {obdvigatel: 4.6},{obdvigatel: 4.7},{obdvigatel: 4.8},{obdvigatel: 4.9},{obdvigatel: 5},{obdvigatel: 5.1},
        {obdvigatel: 5.2},{obdvigatel: 5.3},{obdvigatel: 5.4},{obdvigatel: 5.5},{obdvigatel: 5.6},{obdvigatel: 5.7},
        {obdvigatel: 5.8},{obdvigatel: 5.9},{obdvigatel: 6},{obdvigatel: 6.1},{obdvigatel: 6.2},{obdvigatel: 6.3},
        {obdvigatel: 6.4},{obdvigatel: 6.5},{obdvigatel: 6.6},{obdvigatel: 6.7},{obdvigatel: 6.8},{obdvigatel: 6.9},
        {obdvigatel: 7},{obdvigatel: 7.1},{obdvigatel: 7.2},{obdvigatel: 7.3},{obdvigatel: 7.4},{obdvigatel: 7.5},
        {obdvigatel: 7.6},{obdvigatel: 7.8},{obdvigatel: 7.9},{obdvigatel: 8},
      ],
    };
  },
  methods: {
    select() {
        this.$store.commit("addobdvigatel",this.obdvigatel);
        this.$router.go(-1); 

    },
  },
  computed: {
    post() {
      return this.$store.getters.getpost;
    },
   },

  mounted (){
    if(this.post.obdvigatel){
      this.obdvigatel=this.post.obdvigatel;
    }
  }
};
</script>
<style scoped>
.block_radio {
  margin-bottom: 1.4em;
}
.divgorod {
  width: 100%;
  color: #777777;
}
.contentgorod {
  font-size: 20px;
  border-bottom: 1px solid #000000;
  padding: 5px 5px 5px 0px;
}
label {
  font-size: 18px;
  width: 100%;
}
.content_podat {
  padding: 10px 5px 65px 10px;
}
</style>