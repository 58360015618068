<template>
   <div class="header_podat">
      <div class="main">
          <table>
              <tr>
                <td>
                  <div onclick="javascript:history.back(); return false;" class="imgback">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ep" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024">
                  <path fill="currentColor" d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z"></path></svg></div>
                </td>
                <td width="100%">
                  <span><b>{{ title }}</b></span>
                </td>
                
              </tr>
            </table>
      </div>  
    </div>  
</template>
<script>
export default {
    props: {   
    title: String,
   }, 
}
</script>
<style scoped>
.header_podat{
  height:50px;
  left: 0px;
  top:0px;
  position: fixed;
  max-width:100%;
  min-width: 100%;
  background-color:#fff;
  box-shadow: 0 0 4px 0 rgba(0,0,0,1);
  padding-top:6px;
  z-index: 1000;
}
.header_podat .main table{
  width:100%;
}
.header_podat .main{
  margin:0 auto;
  max-width: 900px;
  min-width: 250px;
  padding:0px 10px 0px 10px ;
}
.header_podat .main span{
 font-size: 18px;
}
.header_podat .main input{
 font-size: 18px;
 padding:6px;
 width:92%;
 border:1px solid #999;
 border-radius: 5px;
}
.close{
  color:rgb(140, 56, 56);
  width:30px;
  margin-bottom: -5px;
} 
</style>