<template>
  <div>
   <Header :title="title"/>

    <div class="content_podatt">
        <div class="content_upload_img">
           <div>
            <img :src="imageupdate" width="200px">
           </div>
            <div align="center">
                <image-uploader
                :maxWidth="900"
                :quality="0.8"
                :preview="true"
                accept="image/*"
                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                capture="environment"
                :debug="1"
                doNotResize="gif"
                :autoRotate= rotate
                outputFormat="verbose"
                @input="setImage"
                @onUpload="startImageResize"
                @onComplete="endImageResize"
                >
                <label v-if="img_btn" for="fileInput" slot="upload-label">
                <div class="btnuploadimg" v-if="img_btn_upload">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ri" width="50" height="50" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993v9.349A5.99 5.99 0 0 0 20 13V5H4l.001 14l9.292-9.293a.999.999 0 0 1 1.32-.084l.093.085l3.546 3.55a6.003 6.003 0 0 0-3.91 7.743L2.992 21A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4a2 2 0 0 1 0-4z"></path></svg>
                    <div><span>Добавить фото</span></div>
                </div>
                <div v-else  class="spin_bnt_uploadimg">
                <Spinner />
                </div>
                </label>
            </image-uploader>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/headerpodat'
import Spinner from '../blocks/spinner';
import axios from "axios";
export default {
   components:{
   Header,Spinner
   },
  data() {
    return {
      title:"Изменить фото",
      hasImage: false,
      image: null,
      fotoedit:'',
      sUsrAg:"",
      imageupdate:this.$route.params.image,
      rotate:true,
      img_btn_upload: true,
      img_btn:true,
      name: 'WebsymbolHeartEmpty',
      foto:[]
     
    };
  },
  methods: {
     setImage: function(output) {
      this.hasImage = true;
      this.image =output.dataUrl;
      this.text_success_img="";
     
    },

 
    startImageResize: function (){
      this.img_btn_upload= false;
    }, 
    endImageResize: function (){
     this.uploadimg();
   },
   uploadimg(){
    axios({
          method: "post",
         url: "https://imgagros.ru/api/upload",
            data: {
              image: this.image,
            },
        })
          .then((res) => {
            if (res.data.status) {
              
               for(var i=0; i<this.foto.length; i++ )
               if(this.foto[i].image == this.imageupdate){
                   this.foto[i].image=res.data.answer
               }
               
              
               this.$store.commit("addimages", this.foto);
               this.delete_img();

            } else {
             this.img_btn_upload= true;
            }
          })
          .catch((error) => {
            if (!error.response) {
              alert("Error111");
            }
          });
   },
    delete_img(){
       axios({
           method: 'post',
            url: 'https://avtobaza.tj/api/delimg',
            data: {
            image_del:this.imageupdate, pass:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
            if(res.data.status){
              this.$router.go(-1);
            }
           
        }).catch(error => {
         alert("error")
      })  
   },
  },
  computed: {
     getfoto() {
      return this.$store.getters.getimages;
    },
   },
  created() {
    if (!localStorage.getItem("tokenadmin")) {
       this.$router.go(-1); 
      } 
  },
  mounted (){
    window.scrollTo(0,0);  
   this.sUsrAg = navigator.userAgent;
   if (this.sUsrAg.indexOf("Chrome") > -1) {
     this.rotate=false;
   }
   if (this.sUsrAg.indexOf("SamsungBrowser") > -1) {
     this.rotate=true;
   }

   this.foto=this.getfoto
   
  }
};
</script>
<style scoped>
#fileInput {
display:none;
}
.btnuploadimg  {
  margin-top:20px;
  width:100%;
  height: 5.5em;
  padding-top:12px;
  border: 2px dashed  #1381fd;
  border-radius: 10px;
}
.spin_bnt_uploadimg{
  width:12em;
  height: 4.5em;
}
.btnuploadimg svg{
  color:#1381fd;
}
.btnuploadimg div {
  color:#1381fd;
  font-size: 19px;
}
.labelfoto {
  margin-top:10px;
  font-size: 18px;
  color:#333;
}
.div_upload_image {
width:100%;
margin:-5px;
}
.div_upload_image div {
position: relative;
border-radius: 10px;
margin: 5px 5px;
display: inline-block;
}

.del_img {
background-color: #ffffff;
position: absolute;
top: 1px;
right: 1px;
width: 22px;
border-radius: 5px;
}
.content_podatt {
  padding: 10px 10px 75px 10px;
}
</style>

