<template>
  <div>
    <Header :title="title"/>
    
    <div class="content_podat">
      <div v-for="p in arrprivod" :key="p.id" class="block_radio">
        <input
          class="custom-radio"
          name="color"
          v-model="privod"
          type="radio"
          :id="p.id"
          :value="p.id"
          @change="selectprivod"
        />
        <label :for="p.id">{{p.text}} </label>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/headerpodat'
export default {
   components:{
   Header
   },
  data() {
    return {
      title:"Выберите привод",
      privod:"",
      txtprivod:"",
       arrprivod: [
        { id: "predniy",text: "Передний"},
        { id: "zadniy",text: "Задний"},
        { id: "polniy",text: "Польный"},
       ],
    };
  },
  methods: {
    selectprivod() {
        const values = Object.values(this.arrprivod);
        for (let i = 0; i < values.length; i += 1) {
          if (values[i].id === this.privod) this.txtprivod = values[i].text;
        }
        this.$store.commit("addprivod",this.privod);
        this.$store.commit("addprivod_text",this.txtprivod);
         this.$router.go(-1);

    },
  },
  computed: {
     post() {
      return this.$store.getters.getpost;
    },
   },

  mounted (){
    if(this.post.privod){
      this.privod=this.post.privod;
    }
  }
};
</script>
<style scoped>
.block_radio {
  margin-bottom: 1.4em;
}
.divgorod {
  width: 100%;
  color: #777777;
}
.contentgorod {
  font-size: 20px;
  border-bottom: 1px solid #000000;
  padding: 5px 5px 5px 0px;
}
label {
  font-size: 18px;
  width: 100%;
}
.content_podat {
  padding: 40px 5px 65px 10px;
}
</style>