<template>
    <div >
      <Menu />
      <div class="cotinerdobv" >
       <table width="100%" >
           <tr>
            <div v-if="btn_natif_date">
                <td><button class="btns" @click="send_natif_date()">Отпрвить сообщения о дата объявления</button></td>
            </div>
               
           </tr>
       </table>
       <br/><br/>
       <div  v-if="info_natif_date">
        <span>Для <b>{{countnatifdate}}</b> объявление упешно отправлено сообщения о услуга "Обновить дата" </span>
       </div>
       
      </div>
      <div class="cotinerdobv" >
       <table width="100%" >
           <tr>
            <div v-if="btn_status_del">
                <td><button class="btnp" @click="status_to_del()">Изменить статус на удалении старый объявления</button></td>
            </div>
               
           </tr>
       </table>
       <br/><br/>
       <div  v-if="info_status_del">
        <span> <b>{{countstatusdel}}</b> старый объявление изменился статус на удалении </span>
       </div>
       
      </div>
      <div class="cotinerdobv" >
       <table width="100%" >
           <tr>
            <div v-if="btn_del_old_obvs">
                <td><button class="btnd" @click="del_old_obvs()">Удалить все старый объявление навсегда</button></td>
            </div>
               
           </tr>
       </table>
       <br/><br/>
       <div  v-if="info_del_old_obvs">
        <span><b>{{countdeloldobvs}}</b> старый объявление упешно удалено навсегда </span>
       </div>
       
      </div>
      <modalupload v-if="startupload" >
      <div slot="body">
        <table><tr><td><Spinner /></td><td ><span class="font_22">Идёт загрузка...</span></td></tr></table>
         
      </div>
      </modalupload>
     </div> 
   </template>
   
   <script>
   
   import Spinner from '../blocks/spinner';
   import Menu from '../blocks/menu';
   import modalupload from '../blocks/modalupload';
   import axios from "axios";
   export default {
    
     components:{
       Spinner, modalupload, Menu  
     },
     data() {
        return {
        startupload:false,
        info_natif_date:false,
        btn_natif_date:true,
        countnatifdate:"",
        countstatusdel:"",
        countdeloldobvs:"",
        btn_del_old_obvs:true,
        info_del_old_obvs:false,
        btn_status_del:true,
        info_status_del:false,
        };
     },
     methods: {

     send_natif_date(){
      this.startupload=true;
         try{
            axios({
              method: 'post',
               url: 'https://avtobaza.tj/api/natifdate',
               data: {
               tokenadmin:localStorage.getItem("tokenadmin")
              }
             })
           .then(res=>{
               if(res.data.status){
                 this.startupload=false;
                 this.btn_natif_date=false;
                 this.info_natif_date=true;
                 this.countnatifdate=res.data.count;
               }
               else{
                this.startupload=false; 
                 alert("Error");
               } 
           })    
        } 
        catch (e){
            alert ("Error");
        }
     },
     
   
     status_to_del(){
      this.startupload=true;
         try{
            axios({
              method: 'post',
               url: 'https://avtobaza.tj/api/statustodel',
               data: {
               tokenadmin:localStorage.getItem("tokenadmin")
              }
             })
           .then(res=>{
               if(res.data.status){
                 this.startupload=false;
                 this.btn_status_del=false;
                 this.info_status_del=true;
                 this.countstatusdel=res.data.count;
               }
               else{
                this.startupload=false; 
                 alert("Error");
               } 
           })    
        } 
        catch (e){
            alert ("Error");
        }
     },

     del_old_obvs(){
      this.startupload=true;
         try{
            axios({
              method: 'post',
               url: 'https://avtobaza.tj/api/delstariyobvs',
               data: {
               tokenadmin:localStorage.getItem("tokenadmin")
              }
             })
           .then(res=>{
               if(res.data.status){
                 this.startupload=false;
                 this.btn_del_old_obvs=false;
                 this.info_del_old_obvs=true;
                 this.countdeloldobvs=res.data.count;
               }
               else{
                this.startupload=false; 
                 alert("Error");
               } 
           })    
        } 
        catch (e){
            alert ("Error");
        }
     },

     },
     mounted(){
   
   
     },
     created(){
      if(!localStorage.getItem("tokenadmin")){
          this.$router.push({name:"Home"});
       }     
   },
   };
   </script>
   
   <style  scoped>
   
   .contentdelobv span{
       font-size: 20px;
   }
   .contentdelobv label{
       font-size: 18px;
   }
   
   </style>
   