<template>
  <div>
   <Header :title="title"/>
    

    <div class="content_podat">
      <div v-for="k in arr" :key="k.id" class="block_radio">
        <input
          class="custom-radio"
          name="color"
          v-model="kuzov"
          type="radio"
          :id="k.id"
          :value="k.id"
          @change="select"
        />
        <label :for="k.id">{{k.text}} </label>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/headerpodat'
export default {
   components:{
   Header
   },
  data() {
    return {
      title:"Выберите кузов",
      kuzov: "",
      txtkuzov: "",
      set:this.$route.params.set,
       arr: [
        { id: "sedan",text: "Седан"},
        { id: "xetchbek",text: "Хэтчбек"},
        { id: "vnedrozhnik",text: "Внедорожник"},
        { id: "universal",text: "Универсал"},
        { id: "kapriolet",text: "Кабриолет"},
        { id: "kroccover",text: "Кроссовер"},
        { id: "kupe",text: "Купе"},
        { id: "limuzin",text: "Лимузин"},
        { id: "microavtobus",text: "Микроавтобус"},
        { id: "miniven",text: "Минивэн"},
        { id: "mikroven",text: "Микровэн"},
        { id: "pikap",text: "Пикап"},
        { id: "rodster",text: "Родстер"},
        { id: "furgon",text: "Фургон"},
      ],
    };
  },
  methods: {
    select() {
        const values = Object.values(this.arr);
        for (let i = 0; i < values.length; i += 1) {
          if (values[i].id === this.kuzov) this.txtkuzov = values[i].text;
        }
       
          this.$store.commit("addkuzov",this.kuzov);
          this.$store.commit("addkuzov_text",this.txtkuzov);
          this.$router.go(-1); 
        
    },
  },
  computed: {
    post() {
      return this.$store.getters.getpost;
    },
   },
  mounted (){
    if(this.post.kuzov){
      this.kuzov=this.pos.kuzov;
    }
  }
};
</script>
<style scoped>
.block_radio {
  margin-bottom: 1.4em;
}
.divgorod {
  width: 100%;
  color: #777777;
}
.contentgorod {
  font-size: 20px;
  border-bottom: 1px solid #000000;
  padding: 5px 5px 5px 0px;
}
label {
  font-size: 18px;
  width: 100%;
}
.content_podat {
  padding: 10px 5px 65px 10px;
}
</style>