<template>
  <div>
    <Header :title="title"/>

    <div class="content_podat">
      <div v-for="t in arrtopliva" :key="t.id" class="block_radio">
        <input
          class="custom-radio"
          name="color"
          v-model="topliva"
          type="radio"
          :id="t.id"
          :value="t.id"
          @change="selecttopliva"
        />
        <label :for="t.id">{{t.text}} </label>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/headerpodat'
export default {
   components:{
   Header
   },
  data() {
    return {
      title:"Выберите тип топлива",
      topliva: "",
      txttopliva: "",
       arrtopliva: [
        { id: "benzin",text: "Бензин"},
        { id: "gazbenzin",text: "Газ-бензин"},
        { id: "dizel",text: "Дизель"},
        { id: "electro",text: "Электричество"},
        { id: "gibrid",text: "Гибрид"},
        { id: "gaz",text: "Газ"},
       ],
    };
  },
  methods: {
    selecttopliva() {
        const values = Object.values(this.arrtopliva);
        for (let i = 0; i < values.length; i += 1) {
          if (values[i].id === this.topliva) this.txttopliva = values[i].text;
        }
      
          this.$store.commit("addtopliva",this.topliva);
          this.$store.commit("addtopliva_text",this.txttopliva);
          this.$router.go(-1);
        
        

    },
   
  },
  computed: {
    post() {
      return this.$store.getters.getpost;
    },
     
   },
  mounted (){
    if(this.post.topliva){
      this.topliva=this.post.topliva;
    }
  }
};
</script>
<style scoped>
.block_radio {
  margin-bottom: 1.4em;
}
.divgorod {
  width: 100%;
  color: #777777;
}
.contentgorod {
  font-size: 20px;
  border-bottom: 1px solid #000000;
  padding: 5px 5px 5px 0px;
}
label {
  font-size: 18px;
  width: 100%;
}
.content_podat {
  padding: 40px 5px 65px 10px;
}
</style>