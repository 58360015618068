<template>
  <div>
    
    <div class="content_podat">
    <b>Комплект</b><hr/>
      <div v-for="k in arrkompleckt" :key="k.id" class="block_radio">
        <input
          class="custom-radio"
          name="color"
          v-model="kompleckt"
          type="radio"
          :id="k.id"
          :value="k.id"
          @change="selectkompleckt"
        />
        <label :for="k.id">{{k.text}} </label>
      </div>
 
      <div v-if="this.kompleckt">
        <div v-if="this.kompleckt=='shini' || this.kompleckt=='diskishini'">
         <b>Тип шины </b><br/>
            <select v-model="tipshina" @change="dale()">
                <option disabled value=""></option>
                <option>Летние</option>
                <option>Всесезонные</option>
                <option>Зимние</option>
            </select>
            <br/><br/>
        </div>
        <div v-if="this.kompleckt=='diski' || this.kompleckt=='diskishini'">
         <b>Тип диска </b><br/>
            <select v-model="tipdiski" @change="dale()">
                <option disabled value=""></option>
                <option>Литые(легкосплавные)</option>
                <option>Штампованные</option>
                <option>Кованые</option>
                <option>Ковано-литые</option>
                <option>Сборные</option>
            </select>
            <br/><br/>
        </div>
        <div v-if="this.kompleckt=='shini' || this.kompleckt=='diskishini'">
           <b>Ширина</b><br/>
            <select v-model="shirina" @change="dale()">
            <option disabled value=""></option>
            <option>125мм</option>
            <option>135мм</option>
            <option>145мм</option>
            <option>155мм</option>
            <option>165мм</option>
            <option>175мм</option>
            <option>185мм</option>
            <option>195мм</option>
            <option>205мм</option>
            <option>215мм</option>
            <option>225мм</option>
            <option>235мм</option>
            <option>245мм</option>
            <option>250мм</option>
            <option>255мм</option>
            <option>265мм</option>
            <option>275мм</option>
            <option>285мм</option>
            <option>295мм</option>
            <option>305мм</option>
            <option>315мм</option>
            <option>325мм</option>
            <option>335мм</option>
            <option>345мм</option>
            <option>355мм</option>
            <option>375мм</option>
            <option>395мм</option>
            </select>
          <br/><br/>
        </div>
        <div v-if="this.kompleckt=='diski' || this.kompleckt=='diskishini'">
           <b>Крепеж (PCD)</b><br/>
            <select v-model="pcd" @change="dale()">
            <option disabled value=""></option>
            <option>3x98</option>
            <option>3x112</option>
            <option>4x100</option>
            <option>4x108</option>
            <option>4x110</option>
            <option>4x114.3</option>
            <option>4x98</option>
            <option>5x98</option>
            <option>5x100</option>
            <option>5x105</option>
            <option>5x108</option>
            <option>5x110</option>
            <option>5x112</option>
            <option>5x114.3</option>
            <option>5x115</option>
            <option>5x118</option>
            <option>5x120</option>
            <option>5x120.65</option>
            <option>5x127</option>
            <option>5x130</option>
            <option>5x135</option>
            <option>5x139.7</option>
            <option>5x150</option>
            <option>5x160</option>
            <option>5x160.5</option>
            <option>5x165</option>
            <option>5x165.1</option>
            <option>6x98</option>
            <option>6x108</option>
            <option>6x114.3</option>
            <option>6x120</option>
            <option>6x127</option>
            <option>6x130</option>
            <option>6x135</option>
            <option>6x138</option>
            <option>6x139</option>
            <option>6x139.7</option>
            <option>6x150</option>
            <option>6x180</option>
            <option>8x165.1</option>
            <option>8x170</option>
            
            </select>
          <br/><br/>
        </div>
        <div v-if="this.kompleckt=='shini' || this.kompleckt=='diskishini'">
           <b>Профил</b><br/>
        <select v-model="profil" @change="dale()">
        <option disabled value=""></option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
        <option>50</option>
        <option>55</option>
        <option>60</option>
        <option>65</option>
        <option>70</option>
        <option>75</option>
        <option>80</option>
        <option>82</option>
        <option>85</option>
        <option>90</option>
        <option>8.5</option>
        <option>9.5</option>
        <option>10.5</option>
        <option>11.5</option>
        <option>12</option>
        <option>13.5</option>
        <option>14</option>
        <option>14.5</option>
        <option>15</option>
        <option>15.5</option>
        <option>16.5</option>
        <option>18.5</option>
        </select>
        <br/><br/>
        </div>
       
       <div>
        <b>Диаметр</b><br/>
        <select v-model="diametr" @change="dale()">
        <option disabled value=""></option>
        <option>R12</option>
        <option>R13</option>
        <option>R14</option>
        <option>R15</option>
        <option>R16</option>
        <option>R17</option>
        <option>R18</option>
        <option>R19</option>
        <option>R20</option>
        <option>R21</option>
        <option>R22</option>
        <option>R23</option>
        <option>R24</option>
        </select>
       </div>
       

      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/headerpodat'
export default {
   components:{
   Header
   },
  data() {
    return {
      title:"Выберите параметры",
      kompleckt:"",
      txtkompleckt:"",
      tipshina:"",
      shirina:"",
      profil:"",
      diametr:"",
      tipdiski:"",
      pcd:"",

       arrkompleckt: [
        { id: "shini",text: "Шины"},
        { id: "diski",text: "Диски"},
        { id: "diskishini",text: "Диски + Шины"},
       ],
    };
  },
  methods: {
    selectkompleckt() {
        const values = Object.values(this.arrkompleckt);
        for (let i = 0; i < values.length; i += 1) {
          if (values[i].id === this.kompleckt) this.txtkompleckt = values[i].text;
        }
        this.$store.commit("addkompleckt",this.kompleckt);
        this.$store.commit("addkompleckt_text",this.txtkompleckt);

    },
    dale() {
      this.$store.commit("addtipshina",this.tipshina);
      this.$store.commit("addtipdiski",this.tipdiski);
      this.$store.commit("addprofil",this.profil);
      this.$store.commit("adddiametr",this.diametr);
      this.$store.commit("addshirina",this.shirina);
      this.$store.commit("addpcd",this.pcd);
    },
  },
  computed: {
     post() {
      return this.$store.getters.getpost;
    },
   },
   mounted (){
    if(this.post.kompleckt){
      this.kompleckt=this.post.kompleckt;
    }
    if(this.post.tipshina){
      this.tipshina=this.post.tipshina;
    }
    if(this.post.tipdiski){
      this.tipdiski=this.post.tipdiski;
    }
    if(this.post.profil){
      this.profil=this.post.profil;
    }
    if(this.post.shirina){
      this.shirina=this.post.shirina;
    }
    if(this.post.diametr){
      this.diametr=this.post.diametr;
    }
    if(this.post.pcd){
      this.pcd=this.post.pcd;
    }
  }
};
</script>
<style scoped>
.block_radio {
  margin-bottom: 1.4em;
}
.divgorod {
  width: 100%;
  color: #777777;
}
.contentgorod {
  font-size: 20px;
  border-bottom: 1px solid #000000;
  padding: 5px 5px 5px 0px;
}
label {
  font-size: 18px;
  width: 100%;
}
.content_podat {
  margin-bottom: 10px;
}
b{
  font-size: 15px;
  color:#555;
}
select {
width:98%;
font-size: 18px;
padding: 10px 3px;
border-bottom: 1px solid #888;
border-radius: 5px;
}
select option {
font-size: 18px;
width:50%;
padding: 20px 5px;
}
</style>