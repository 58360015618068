<template>
  <div>
    <Header :title="title" />

    <div class="content_podat">
      <div v-if="loading" align="center">
        <div class="spinpodat"><Spinner /></div>
      </div>
      <div v-else>
        <div v-for="t in arr" :key="t.id" class="block_radio">
          <input
            class="custom-radio"
            name="color"
            v-model="tip"
            type="radio"
            :id="t.id_tip"
            :value="t.id_tip"
            @change="select"
          />
          <label :for="t.id_tip">{{ t.text }} </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../components/headerpodat";
import axios from "axios";
import Spinner from "../blocks/spinner";
export default {
  components: {
    Header,
    Spinner,
  },
  data() {
    return {
      title: "Тип ",
      loading: true,
      tip: "",
      txttip: "",
      set:this.$route.params.set,
      arr: [],
    };
  },
  methods: {
    select() {
      const values = Object.values(this.arr);
      for (let i = 0; i < values.length; i += 1) {
        if (values[i].id_tip === this.tip) this.txttip = values[i].text;
      }
       
          this.$store.commit("addtip", this.tip);
          this.$store.commit("addtip_text", this.txttip);
          this.$router.go(-1);
       
      
    },
  },
  computed: {
    post() {
      return this.$store.getters.getpost;
    },
  },
  mounted() {
    axios({
      method: "post",
      url: "https://avtobaza.tj/api/gettip",
      data: {
        id: this.$route.params.id,
      },
    }).then((res) => {
      if (res.data.status) {
        this.loading = false;
        this.arr = res.data.tips;
        if (this.post.tip) this.tip = this.post.tip;
      } else {
        this.loading = false;
        alert("ERROR");
      }
    });
  },
};
</script>
<style scoped>
.block_radio {
  margin-bottom: 1.4em;
}
.divgorod {
  width: 100%;
  color: #777777;
}
.contentgorod {
  font-size: 20px;
  border-bottom: 1px solid #000000;
  padding: 5px 5px 5px 0px;
}
label {
  font-size: 18px;
  width: 100%;
}
.content_podat {
  padding: 90px 5px 65px 10px;
}
</style>