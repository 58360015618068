<template>
   <div>
    <Menu />  
     <div class="content_dialogs">
        <br/>
          <div>  
              <Kartdialogs  :dialog="postdialogs" /> 
          </div>
          <div v-if="loading" align="center">
              <div class="spin">
              <Spinner />
              </div>
            </div>
        <noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
      </div>
   </div>
    
</template>
<script>
import axios from 'axios';
import noconnection from '../blocks/noconnection'
import Spinner from '../blocks/spinner';
import Menu from '../blocks/menu';
import Kartdialogs from '../blocks/kartdialogs';
export default{
components:{
    Spinner, noconnection, Kartdialogs, Menu

  },
data() {
    return {
        title:"Сообщения",
        loading:false,
        propsmodalalert:false,
        postdialogs:[],
        
    }
  },
created(){
    if(!localStorage.getItem("tokenadmin")){
       this.$router.push({name:"Home"});
    }
},
methods:{
 emitnoconnection(){
   this.propsmodalalert=false;
   this.getdialogs();
 },

 getdialogs(){
     if(localStorage.getItem("tokenadmin")){
         this.loading=true;
         axios({
           method: 'post',
            url: 'https://avtobaza.tj/api/getdialogsadmin',
            data: {
            tokenadmin: localStorage.getItem("tokenadmin"),
           }
          })
        .then(res=>{
          if(!res.data.msg){
            if(res.data.status){
               this.postdialogs=res.data.dialogs;
               this.loading=false;
            }
            else{
              this.loading=false;
            }
          }
           else{
             localStorage.removeItem("tokenadmin");
             this.$router.go();
           } 
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
 } }   
},
mounted(){
     
    this.getdialogs();  
}
}
</script>
<style scoped>
.content_dialogs{
  margin:-10px;
}

</style>
