import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../page/Home.vue'
import sessionday from '../page/sessionday.vue'
import moderator from '../page/moderator.vue'
import kod from '../page/kod.vue'
import message from '../page/message.vue'
import chat from '../page/chat.vue'
import dobv from '../page/dobv.vue'
import images from '../page/images.vue'
import kateg from '../components/kateg'
import gorod from '../components/gorod'
import marka from '../components/marka'
import model from '../components/model'
import kuzov from '../components/kuzov'
import color from '../components/color'
import topliva from '../components/topliva'
import karobka from '../components/karobka'
import privod from '../components/privod'
import editfoto from '../components/editfoto'
import obyomdvigatel from '../components/obyomdvigatel'
import opsiya from '../components/opsiya'
import tip from '../components/tip'
import podkateg from '../components/podkateg'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/kateg',
    name: 'kateg',
    component: kateg
  },
  {
    path: '/gorod',
    name: 'gorod',
    component: gorod
  },
  {
    path: '/marka/:id',
    name: 'marka',
    component: marka
  },
  {
    path: '/kuzov',
    name: 'kuzov',
    component: kuzov
  },
  {
    path: '/color',
    name: 'color',
    component: color
  },
  {
    path: '/topliva',
    name: 'topliva',
    component: topliva
  },
  {
    path: '/model/:id',
    name: 'model',
    component: model
  },
  {
    path: '/editfoto/:image',
    name: 'editfoto',
    component: editfoto
  },
  {
    path: '/karobka',
    name: 'karobka',
    component: karobka
  },
  {
    path: '/podkateg/:id',
    name: 'podkateg',
    component: podkateg
  },
  {
    path: 'privod',
    name: 'privod',
    component: privod
  },
  {
    path: 'obyomdvigatel',
    name: 'obyomdvigatel',
    component: obyomdvigatel
  },
  {
    path: 'opsiya',
    name: 'opsiya',
    component: opsiya
  },
  {
    path: 'tip/:id',
    name: 'tip',
    component: tip
  },
  {
    path: '/moderator',
    name: 'moderator',
    component: moderator
  },
  {
    path: '/kod',
    name: 'kod',
    component: kod
  },
  {
    path: '/message',
    name: 'message',
    component: message
  },
  {
    path: '/chat',
    name: 'chat',
    component: chat
  },
  {
    path: '/dobv',
    name: 'dobv',
    component: dobv
  },
  {
    path: '/images',
    name: 'images',
    component: images
  },
  {
    path: '/sessionday',
    name: 'sessionday',
    component: sessionday
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
