<template>
  <div>
    <Header :title="title"/>
    <div class="footer_podat">
       <div class="main">
        <div align="center"><button class="btns" @click="dale()">Далее</button></div>
       </div>
    </div>
    <div class="content_podat">
       <div v-if="loading" align="center">
           <div class="spinpodat">
           <Spinner />
           </div>
        </div> 
      <b>Медиа</b><hr/>
      <div v-for="opsiy in getopsiya" :key="'media'+opsiy.id" class="content">
       <div v-if="opsiy.tip=='media'" :class="{vibran:opsiy.status}" class="nevibran" @click="opsiy.status=!opsiy.status">{{opsiy.text}}</div>
     </div>
     <br/><br/><b>Салон</b><hr/>
     <div v-for="opsiy in getopsiya" :key="'salon'+opsiy.id" class="content">
       <div v-if="opsiy.tip=='salon'"  :class="{vibran:opsiy.status}" class="nevibran" @click="opsiy.status=!opsiy.status">{{opsiy.text}}</div>
     </div>
     <br/><br/><b>Снаружи</b><hr/>
     <div v-for="opsiy in getopsiya" :key="'snaruzhi'+opsiy.id" class="content">
       <div v-if="opsiy.tip=='snaruzhi'" :class="{vibran:opsiy.status}" class="nevibran" @click="opsiy.status=!opsiy.status">{{opsiy.text}}</div>
     </div>
      <br/><br/><b>Оптика</b><hr/>
     <div v-for="opsiy in getopsiya" :key="'optika'+opsiy.id" class="content">
       <div v-if="opsiy.tip=='optika'" :class="{vibran:opsiy.status}" class="nevibran" @click="opsiy.status=!opsiy.status">{{opsiy.text}}</div>
     </div>
     <br/><br/><b>Опции автомобиля</b><hr/>
     <div v-for="opsiy in getopsiya" :key="'opsiyaavto'+opsiy.id" class="content">
       <div v-if="opsiy.tip=='opsiyaavto'" :class="{vibran:opsiy.status}" class="nevibran" @click="opsiy.status=!opsiy.status">{{opsiy.text}}</div>
     </div>
      <br/><br/><b>Дополнительно</b><hr/>
     <div v-for="opsiy in getopsiya" :key="'dopolnitelno'+opsiy.id" class="content">
       <div v-if="opsiy.tip=='dopolnitelno'" :class="{vibran:opsiy.status}" class="nevibran" @click="opsiy.status=!opsiy.status">{{opsiy.text}}</div>
     </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/headerpodat'  
import axios from 'axios'
import Spinner from '../blocks/spinner'
export default {
   components:{
    Spinner, Header 
   },
  data() {
    return {
      title:"Добавьте опции",
       obdvigatel: "",
       loading:false,
    };
  },
  methods: {
    dale() {
     this.$router.go(-1); 
    },
  },
  computed: {
    getopsiya() {
      return this.$store.getters.getopsiya;
    },
    id() {
      return this.$store.getters.getid;
    },
   },
  mounted (){
    if(!this.getopsiya.length){
      this.loading=true;
       axios({
           method: 'post',
            url: 'https://avtobaza.tj/api/getopsiya',
            data: {
             id:this.id,
            }
            
          })
        .then(res=>{
            if(res.data.status){
               this.loading=false;
               this.$store.commit("addpodatopsiya",res.data.opsiya);   
            }
            else {
                this.loading=false;
                alert("ERROR")
            }
        });
    }
  }
};
</script>
<style scoped>

.contentgorod {
  font-size: 20px;
  border-bottom: 1px solid #000000;
  padding: 5px 5px 5px 0px;
}
.content_podat {
  padding: 40px 5px 65px 10px;
}
.nevibran {
  padding:8px;
  background-color: #f5f5f5;
  margin:3px;
  font: Montserrat, sans-serif !important;
	color: #37363c;
  border-radius: 10px;
  font-size: 18px;
}
.vibran {
  background-color: #99ccff;
  color:#222;
}
.content {
display: inline-block;
}
.nevibran:hover {
  cursor:pointer;
}
b{
font-size: 18px;
margin-left:5px;
}
</style>