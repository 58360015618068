<template>
<div>
  <Menu />
  <div align="center">
    <div v-if="tokenadmin">
      <h2>Admin</h2>
      <button class="btns" @click="viyti()">Выйти</button>

      </div>
    <div v-else>
       <div v-if="!spin">
        <input type="password" class="inputt" v-model="pass"/>
        <br/><br/><br/>
        <button  @click="chengpass()" class="btns">ГОТОВО</button>
       </div>
     <Spinner v-if="spin"/>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import Spinner from '../blocks/spinner';
import Menu from '../blocks/menu';
export default {
  name: 'Home',
  components: {
  Spinner, Menu
  },
  data (){
    return {
      pass:"",
      spin:false,
      tokenadmin:localStorage.getItem('tokenadmin'),

    }
  },
  methods:{
   chengpass(){
     if(this.pass.length > 20){
       this.spin=true;
      try{
         axios({
           method: 'post',
            url: 'https://avtobaza.tj/api/passadmin',
            data: {
            pass:this.pass,
           }
          })
        .then(res=>{
            if(res.data.status){
              localStorage.setItem(
                "tokenadmin",
                res.data.tokenadmin
                );
                this.tokenadmin=true;
            }
            else{
             this.spin=false;
            }
        }).catch(error => {
        
      })    
     } 
     catch (e){
         alert ("Error");
     } 
     }
   },
   viyti(){
     localStorage.removeItem("tokenadmin");
     this.$router.go();
   }
  }
}
</script>
