<template>
  <div>
    <div class="header_podat">
      <div class="main">
          <table>
              <tr>
                <td>
                  <div onclick="javascript:history.back(); return false;" class="imgback">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ep" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024">
                  <path fill="currentColor" d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z"></path></svg></div>
                </td>
                <td width="100%">
                  <span><b>Выберите модел</b></span>
                </td>
              </tr>
              <tr>
                <td colspan="2" align="center">
                  <input v-model="search" type="text" placeholder="Поиск по модел">
                </td>
              </tr>
            </table>
      </div>  
    </div>
  
    <div class="content_podat" >
       <div v-if="loading" align="center">
           <div class="spinpodat">
           <Spinner />
           </div>
        </div>
      <div v-else v-for="m in filtermodel" :key="m.id" class="block_radio">
        <input
          class="custom-radio"
          name="color"
          v-model="model"
          type="radio"
          :id="m.id_model"
          :value="m.id_model"
          @change="selectmodel"
        />
        <label :for="m.id_model">{{ m.text }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Spinner from '../blocks/spinner'
export default {
   components:{
    Spinner,
   },
  data() {
    return {
      model: "",
      txtmodel: "",
      search:"",
      loading:true,
      arrmodel: [
        
      ],
    };
  },
  methods: {
    selectmodel() {
        const values = Object.values(this.arrmodel);
        for (let i = 0; i < values.length; i += 1) {
          if (values[i].id_model === this.model) this.txtmodel = values[i].text;
        }
        
      
            this.$store.commit("addmodel",this.model);
            this.$store.commit("addmodel_text",this.txtmodel);
            this.$router.go(-1);
        
       

    },
  },
  computed: {
    post() {
      return this.$store.getters.getpost;
    },
    filtermodel: function (){
      return this.arrmodel.filter((m)=>{
      return m.text.toUpperCase().match(this.search.toUpperCase());
      })
    },
   },
  mounted (){
    axios({
           method: 'post',
            url: 'https://avtobaza.tj/api/getmodels',
            data: {
            id:this.$route.params.id,
           }
          })
        .then(res=>{
            if(res.data.status){
               this.loading=false;
               this.arrmodel=res.data.models;
               if(this.post.model)  this.model=this.post.model; 
            }
            else {
                this.loading=false;
                alert("ERROR")
            }
        });
  }
};
</script>
<style scoped>
.block_radio {
  margin-bottom: 1.4em;
}
.divmodel {
  width: 100%;
  color: #777777;
}
.contentmodel {
  font-size: 20px;
  border-bottom: 1px solid #000000;
  padding: 5px 5px 5px 0px;
}
label {
  font-size: 18px;
  width: 100%;
}
.content_podat {
  padding: 40px 5px 65px 10px;
}
.header_podat{
  height:85px;
  left: 0px;
  top:0px;
  position: fixed;
  max-width:100%;
  min-width: 100%;
  background-color:#fff;
  box-shadow: 0 0 4px 0 rgba(0,0,0,1);
  padding-top:6px;
  z-index: 1000;
}
.header_podat .main table{
  width:100%;
}
.header_podat .main{
  margin:0 auto;
  max-width: 900px;
  min-width: 250px;
  padding:0px 10px 0px 10px ;
}
.header_podat .main span{
 font-size: 18px;
}
.header_podat .main input{
 font-size: 18px;
 padding:6px;
 width:94%;
 border:1px solid #999;
 border-radius: 5px;
}
</style>