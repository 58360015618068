<template>
  <div>
  <Header :title="title"/>

    <div class="content_podat">
      <div v-for="k in arrkarobka" :key="k.id" class="block_radio">
        <input
          class="custom-radio"
          name="color"
          v-model="karobka"
          type="radio"
          :id="k.id"
          :value="k.id"
          @change="selectkarobka"
        />
        <label :for="k.id">{{k.text}} </label>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/headerpodat'
export default {
   components:{
   Header
   },
  data() {
    return {
      title:"Выберите каробка передач",
      karobka:"",
      set:this.$route.params.set,
      txtkarobka:"",
       arrkarobka: [
        { id: "mexanika",text: "Механика"},
        { id: "avtomat",text: "Автомат"},
        { id: "tiptronik",text: "Типтроник"},
        { id: "variator",text: "Вариатор"},
        { id: "robot",text: "Робот"},
       ],
    };
  },
  methods: {
    selectkarobka() {
        const values = Object.values(this.arrkarobka);
        for (let i = 0; i < values.length; i += 1) {
          if (values[i].id === this.karobka) this.txtkarobka = values[i].text;
        }
      
          this.$store.commit("addkarobka",this.karobka);
          this.$store.commit("addkarobka_text",this.txtkarobka);
          this.$router.go(-1);
       

    },

  },
  computed: {
     post() {
      return this.$store.getters.getpost;
    },
   },
  mounted (){
     if(this.post.karobka){
      this.karobka=this.post.karobka;
    }
  }
};
</script>
<style scoped>
.block_radio {
  margin-bottom: 1.4em;
}
.divgorod {
  width: 100%;
  color: #777777;
}
.contentgorod {
  font-size: 20px;
  border-bottom: 1px solid #000000;
  padding: 5px 5px 5px 0px;
}
label {
  font-size: 18px;
  width: 100%;
}
.content_podat {
  padding: 20px 5px 65px 10px;
}
</style>